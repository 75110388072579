import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant"
import {
    TOPIC_SLICE,
    TOPIC_API_URL
} from 'constants/topics/index'
import CommonService from 'services/CommonService/CommonService'
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));
export const initialState = {
    loading: false,
    tableLoading: true,
    topics: null,
    topicLabel: "Add Topic",
    editData: null,
    errors: {},
    showMessage: false,
    openTopicModal: false,
    sorting: {},
    filter: {},
    topicPagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
}
export const getAllTopics = createAsyncThunk('getAllTopics', async (data) => {
    try {
        return CommonService.getData(data, TOPIC_API_URL)        
    } catch (err) {
        throw new Error(JSON.stringify(err.response.data.errors))
    }
})
export const addTopic = createAsyncThunk('addTopic', async (data) => {
    try {
        // return data && data.hasOwnProperty('id') ? 
        // await CommonService.putData(data, TOPIC_API_URL) :
       return await CommonService.postData(data, TOPIC_API_URL) 
    } catch (err) {
        throw new Error(JSON.stringify(err.response.data.errors))
    }
})
export const deleteTopic = createAsyncThunk('deleteTopic', async (data) => {
    try {
        return CommonService.deleteData(data,TOPIC_API_URL)        
    } catch (err) {
        throw new Error(JSON.stringify(err.response.data.errors))
    }
})


export const topicSlice = createSlice({
    name: TOPIC_SLICE,
    initialState,
    reducers: {
        clearErrors: (state) => {
            state.errors = {};
        },
        setOpenModal: (state) => {
            state.openTopicModal = true
            state.editData = null
            state.topicLabel = 'Add Topic'
        },
        setCloseModal: (state) => {
            state.openTopicModal = false
            state.editData = null
        },
        setEditData: (state, action) => {
            state.openTopicModal = true
            state.editData = action.payload;
            state.topicLabel = 'Edit Topic';

        },
        clearStudentFilter: (state, action) => {
            state.filter = {}
            state.sorting = {}
        },
        updateSortFilters: (state, action) => {
            state.filter = action.payload.filter;
            state.sorting = action.payload.sorting;
        },
        setColumnSearch: (state, action) => {
            state.filter = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllTopics.pending, (state, action) => {
                state.loading = true
                state.tableLoading = true
            })
            .addCase(getAllTopics.fulfilled, (state, action) => {
                state.topics = action.payload.data;
                state.tableLoading = false
                state.loading = false
                state.topicPagination = {
                    ...state.topicPagination,
                    total: action.payload.pagination.total,
                    current: action.payload.pagination.current_page,
                    pageSize: action.payload.pagination.per_page
                };
            })
            .addCase(getAllTopics.rejected, (state, action) => {
                state.loading = false
                state.tableLoading = false
            })
            .addCase(addTopic.pending, (state, action) => {
                state.loading = true
                state.tableLoading = true             
                state.showMessage = false
            })
            .addCase(addTopic.fulfilled, (state, action) => {
                state.loading = false
                state.tableLoading = false
                state.errors = {}
                state.showMessage = false
            })
            .addCase(addTopic.rejected, (state, action) => {
                state.errors = JSON.parse(action.error.message)
                state.showMessage = true
                state.loading = false
                state.tableLoading = false
            })
            .addCase(deleteTopic.pending, (state, action) => {
                // state.loading = true
                // state.tableLoading = true
            })
            .addCase(deleteTopic.fulfilled, (state, action) => {
                // state.loading = false
                // state.tableLoading = false
            })
            .addCase(deleteTopic.rejected, (state, action) => {
                state.errors = JSON.parse(action.error.message)
                state.showMessage = true;
                state.loading = false
                state.tableLoading = false
            })
    },
})

export const {
    setOpenModal,
    setCloseModal,
    clearErrors,
    setEditData,
    updateSortFilters,
    setColumnSearch
} = topicSlice.actions

export default topicSlice.reducer