import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  RECEIVABLES_REPORT,
  RECEIVABLES_REPORT_API_URL,
  GET_ALL_RECEIVABLES_REPORT_API_URL,
  STUDENT_RECEIVEABLE_REPORT,
  GET_STUDENT_INVOICES_API_URL,
  GET_STUDENT_PAYMENTS_API_URL,
  STUDENT_RECEIVABLE_SUMMARY_REPORT
} from 'constants/AppConstants';
import {
  USER_ALL_INFORMATION_ORGANIZATION_WISE
} from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
import { USER_INFORMATION } from "constants/AuthConstant";

const userInformation = JSON.parse(localStorage.getItem(USER_INFORMATION));
const selectedOrganization = userInformation?.organization_id ?? null;
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  ReceivablesReportErrors: {},
  permission: permission?.receivables_report ?? [],
  ReceivablesReportShowMessage: null,
  ReceivablesReportResult: [],
  ReceivablesReportButtonSpinner: false,
  ReceivablesReportTableLoading: true,
  ReceivablesReportAddDrawer: false,
  ReceivablesReportEditData: [],
  ReceivablesReportButtonAndModelLabel: setLocale('receivablesreport.add'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  TableInvoicePagination: {
    current: 1,
    pageSize: 4,
    total: 0,
  },
  StudentInvoiceResult: [],
  InvoiceDetailResult: [],
  ReceivableSummaryResult: [],
  ReceivableSummaryTableLoading: true,
  PaymentDetailResult: [],
  StudentInvoiceTableLoading: true,

  TablePaymentPagination: {
    current: 1,
    pageSize: 4,
    total: 0,
  },
  StudentPaymentResult: [],
  StudentPaymentTableLoading: true,

  sorting: {},
  filterData: {
    date: null,
    organization_id: selectedOrganization,
    organization_grade_level_id: null,
    student_id: null
  },

  tablePaginationReceivableSummary: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  filterReceivableSummary: {},
  sortingReceivableSummary: {},
  DrawerStatus: 0,

  ViewReceivablesReportData: null,
  ViewReceivablesReportLoader: false,

  InvoicePaymentSummaryTabStatus: false,
  ReceivableSummaryTabStatus: true

};

export const getStudentReceiveableReport = createAsyncThunk("getStudentReceiveableReport", async (data) => {
  try {
    return await CommonService.postData(data, STUDENT_RECEIVEABLE_REPORT);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
});

export const createReceivablesReport = createAsyncThunk(
  "createReceivablesReport",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, RECEIVABLES_REPORT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getReceivablesReport = createAsyncThunk(
  "getReceivablesReport",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_RECEIVABLES_REPORT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getStudentInvoices = createAsyncThunk(
  "getStudentInvoices",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_STUDENT_INVOICES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getStudentPayment = createAsyncThunk(
  "getStudentPayment",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_STUDENT_PAYMENTS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewReceivablesReport = createAsyncThunk(
  "viewReceivablesReport",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, RECEIVABLES_REPORT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteReceivablesReport = createAsyncThunk(
  "deleteReceivablesReport",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, RECEIVABLES_REPORT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getStudentReceivableSummaryReport = createAsyncThunk("getStudentReceivableSummaryReport", async (data) => {
  try {
    return await CommonService.postData(data, STUDENT_RECEIVABLE_SUMMARY_REPORT);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
});


export const manageReceivablesReportSlice = createSlice({
  name: RECEIVABLES_REPORT,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.ReceivablesReportErrors = {};
    },
    ReceivablesReportAddDrawerStatus: (state, action) => {
      if (action.payload.status === false) {
        state.ReceivablesReportButtonSpinner = false;
      }
      state.ReceivablesReportAddDrawer = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.ReceivablesReportEditData = [];
      state.ReceivablesReportButtonAndModelLabel = setLocale('receivablesreport.add');
    },
    ReceivablesReportEditWithDrawerStatus: (state, action) => {
      state.ReceivablesReportAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.ReceivablesReportEditData = action.payload.data;
      state.ReceivablesReportButtonAndModelLabel = setLocale('receivablesreport.edit');
    },
    updateSortFilters: (state, action) => {
      state.filterData = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filterData = action.payload;
    },
    updateSortFiltersReceivableSummary: (state, action) => {
      state.filterReceivableSummary = action.payload.filter;
      state.sortingReceivableSummary = action.payload.sorting;
    },
    setColumnSearchReceivableSummary: (state, action) => {
      state.filterReceivableSummary = action.payload;
    },
    invoiceDetail: (state, action) => {
      state.InvoiceDetailResult = action.payload;
    },
    paymentDetail: (state, action) => {
      state.PaymentDetailResult = action.payload;
    },
    tabStatusHandle: (state, action) => {
      if (action.payload === 'receivable_summary') {
        state.ReceivableSummaryTabStatus = true;
        state.InvoicePaymentSummaryTabStatus = false;
      } else {
        state.ReceivableSummaryTabStatus = false;
        state.InvoicePaymentSummaryTabStatus = true;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStudentReceiveableReport.pending, (state, action) => {
        state.ViewReceivablesReportLoader = true;
        state.ViewReceivablesReportData = null;
      }).addCase(getStudentReceiveableReport.fulfilled, (state, action) => {
        state.ViewReceivablesReportData = action.payload;
        state.ViewReceivablesReportLoader = false;
      }).addCase(getStudentReceiveableReport.rejected, (state, action) => {
        state.ViewReceivablesReportLoader = false;
      })

      .addCase(getStudentReceivableSummaryReport.pending, (state, action) => {
        state.ReceivableSummaryTableLoading = true;
        state.ReceivableSummaryResult = [];
      }).addCase(getStudentReceivableSummaryReport.fulfilled, (state, action) => {
        state.ReceivableSummaryTableLoading = false;
        state.ReceivableSummaryResult = action.payload;
        console.log(action.payload);
        state.tablePaginationReceivableSummary = {
          ...state.tablePaginationReceivableSummary,
          total: action.payload?.pagination?.total,
          current: action.payload?.pagination?.current_page,
          pageSize: action.payload?.pagination?.per_page,
        };
      }).addCase(getStudentReceivableSummaryReport.rejected, (state, action) => {
        state.ReceivableSummaryTableLoading = false;
      })

      .addCase(createReceivablesReport.pending, (state, action) => {
        state.DrawerStatus = 0
        state.ReceivablesReportButtonSpinner = true;
      }).addCase(createReceivablesReport.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.ReceivablesReportButtonSpinner = false;
        state.ReceivablesReportErrors = {}
      }).addCase(createReceivablesReport.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.ReceivablesReportShowMessage = true;
        state.ReceivablesReportButtonSpinner = false;
        state.ReceivablesReportErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(getReceivablesReport.pending, (state, action) => {
        state.ReceivablesReportButtonSpinner = true;
        state.ReceivablesReportTableLoading = true;
      })
      .addCase(getReceivablesReport.fulfilled, (state, action) => {
        state.ReceivablesReportButtonSpinner = false;
        state.ReceivablesReportTableLoading = false;
        state.ReceivablesReportResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.total,
          current: action.payload.current_page,
          pageSize: action.payload.per_page
        };
      })
      .addCase(getReceivablesReport.rejected, (state, action) => {
        state.ReceivablesReportShowMessage = true; // Set the showMessage flag to display the errors
        state.ReceivablesReportButtonSpinner = false;
        state.ReceivablesReportTableLoading = false;
        state.ReceivablesReportErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })

      .addCase(getStudentInvoices.pending, (state, action) => {
        state.StudentInvoiceTableLoading = true;
      })
      .addCase(getStudentInvoices.fulfilled, (state, action) => {
        state.StudentInvoiceTableLoading = false;
        state.StudentInvoiceResult = action.payload;

        // Ensure invoice_detail exists and has at least one entry
        state.InvoiceDetailResult = Array.isArray(action.payload?.data) && action.payload?.data.length > 0
          ? action.payload.data[0].invoice_detail // Get the first index (index 0)
          : null; // Set to null if not available

        state.TableInvoicePagination = {
          ...state.TableInvoicePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      })
      .addCase(getStudentInvoices.rejected, (state, action) => {
        state.StudentInvoiceTableLoading = false;
      })


      .addCase(getStudentPayment.pending, (state, action) => {
        state.StudentPaymentTableLoading = true;
      })
      .addCase(getStudentPayment.fulfilled, (state, action) => {
        state.StudentPaymentTableLoading = false;
        state.StudentPaymentResult = action.payload;

        // Ensure invoice_detail exists and has at least one entry
        state.PaymentDetailResult = Array.isArray(action.payload?.data) && action.payload?.data.length > 0
          ? action.payload.data[0].invoicePayment // Get the first index (index 0)
          : null; // Set to null if not available

        state.TablePaymentPagination = {
          ...state.TablePaymentPagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      })
      .addCase(getStudentPayment.rejected, (state, action) => {
        state.StudentPaymentTableLoading = false;
      })


      .addCase(viewReceivablesReport.pending, (state, action) => {
        state.ViewReceivablesReportLoader = true;
        state.ViewReceivablesReportData = [];
      }).addCase(viewReceivablesReport.fulfilled, (state, action) => {
        state.ViewReceivablesReportLoader = false;
        state.ViewReceivablesReportData = action.payload;
      }).addCase(viewReceivablesReport.rejected, (state, action) => {
        state.ViewReceivablesReportLoader = false;
        state.ViewReceivablesReportData = [];
      })
      .addCase(deleteReceivablesReport.pending, (state, action) => {
        state.ReceivablesReportTableLoading = true;
      }).addCase(deleteReceivablesReport.fulfilled, (state, action) => {
        state.ReceivablesReportTableLoading = false;
      }).addCase(deleteReceivablesReport.rejected, (state, action) => {
        state.ReceivablesReportTableLoading = false;
      });
  },
});

export const { paymentDetail, invoiceDetail, tabStatusHandle,
  setColumnSearchReceivableSummary, updateSortFiltersReceivableSummary,
  onCloseError, setColumnSearch, ReceivablesReportAddDrawerStatus, ReceivablesReportEditWithDrawerStatus, updateSortFilters } = manageReceivablesReportSlice.actions;

export default manageReceivablesReportSlice.reducer;
