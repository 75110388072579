import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  STUDENT_GRADING, 
  GET_CLASS_STUDENTS_FOR_GRADES,
  VIEW_SINGLE_STUDENT_GRADE_DETAIL,
  PRE_LOAD_STUDENT_GRADE,
  SCHOOL_TERMS,
  SINGLE_STUDENT_GRAPH_DATA,
  SINGLE_SUBJECT_DETAIL
} from 'constants/student-grading/index'
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";

const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  StudentGradingErrors       : {},
  permission: permission?.student_grading ?? [],
  
  gradeTableMode:true,
  viewAs : "percentage",

  class_course_id:null,
  course_id:null,
  organization_grade_level_id:null,
  addQuickAssignmentModal:false,
  assignment_type_id:null,
  class_student_ids:[],
  course_teacher_id:null,  

  mainGradingFilters:false,
  allClassCourseStudents:[],
  preLoadGradingData:[],
  schoolTerms:[],
  termDates:{},

  filters:{},
  loadStudentGrades:false,
  studentGradeHeader:[],
  studentGradeBody:[],
  courseGradeAssignments:[],
  
  studentGradeSummaryBody:[],
  studentGradeSummaryHeader:[],

  singleStudentGradingModal:false,
  loadSingleStudentGrading:false,
  studentCourseHeader:[],
  studentCourseBody:[],
  student:{},

  singleStudentDetailViewType:'table',

  pieChartSeriesData:[],
  pieChartLabelsData:[],
  pieChartOverallData:0,

  barChartSeriesData:[],
  barChartLabelsData:[],
  barChartTableData:[],
  // barChartMedianData:[],

  barMarksMedianData:[],
  barPercentMedianData:[],

  singleSubjectDetailDrawer:false,
  singleSubjectDetail:[],


};

export const getSingleStudentGraphData = createAsyncThunk('getSingleStudentGraphData', async (data) => {
  try {
      return await CommonService.getData(data, SINGLE_STUDENT_GRAPH_DATA);        
  } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors))
  }
})
export const getSingleStudentGradeDetail = createAsyncThunk('getSingleStudentGradeDetail', async (data) => {
  try {
      return await CommonService.getData(data, VIEW_SINGLE_STUDENT_GRADE_DETAIL);        
  } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors))
  }
})
export const getSchoolTerms = createAsyncThunk('getSchoolTerms', async (data) => {
  try {
      return await CommonService.getData(data, SCHOOL_TERMS);        
  } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors))
  }
})
export const getPreLoadGradingData = createAsyncThunk('getPreLoadGradingData', async (data) => {
  try {
      return await CommonService.getData(data, PRE_LOAD_STUDENT_GRADE);        
  } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors))
  }
})
export const getClassStudentsForGrades = createAsyncThunk('getClassStudentsForGrades', async (data) => {
  try {
      return await CommonService.postData(data, GET_CLASS_STUDENTS_FOR_GRADES);        
  } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors))
  }
})
export const getQuickStudentGrades = createAsyncThunk('getQuickStudentGrades', async (data) => {
  try {
      return await CommonService.postData(data, GET_CLASS_STUDENTS_FOR_GRADES);        
  } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors))
  }
})
export const getQuickStudentRubricGrades = createAsyncThunk('getQuickStudentRubricGrades', async (data) => {
  try {
      return await CommonService.postData(data, GET_CLASS_STUDENTS_FOR_GRADES);        
  } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors))
  }
})

export const getSingleSubjectDetail = createAsyncThunk('getSingleSubjectDetail', async (data) => {
  try {
      return await CommonService.postData(data, SINGLE_SUBJECT_DETAIL);        
  } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors))
  }
})

export const manageStudentGradingSlice = createSlice({
  name: STUDENT_GRADING,
  initialState,
  reducers: {
    setSingleSubjectDetailDrawer: (state, action) => {
      state.singleSubjectDetailDrawer = action.payload
    },
    setSingleStudentDetailViewType: (state, action) => {
      state.singleStudentDetailViewType = action.payload
    },
    setSingleStudentGradingModal: (state, action) => {
      state.singleStudentGradingModal = action.payload
    },
    setViewAs: (state, action) => {
      state.viewAs = action.payload
    },
    setGradeTableMode: (state, action) => {
      state.gradeTableMode = action.payload
    },
   setFilterData: (state, action) => {
      state.filters = action.payload
    },
    setTermDates: (state, action) => {
      state.termDates = action.payload
    },
    setMainGradingFilters: (state, action) => {
      state.mainGradingFilters = action.payload
    },
    setAllClassCourseStudents: (state, action) => {    
      state.allClassCourseStudents = action.payload
    },
    setClassCourseId: (state, action) => {
      state.class_course_id = action.payload
    },
    setCourseId: (state, action) => {
      state.course_id = action.payload
    },
    setOrganizationGradeLevelId: (state, action) => {
      state.organization_grade_level_id = action.payload
    },
    setAddQuickAssignmentModal: (state, action) => {
      state.addQuickAssignmentModal = action.payload
    },
    setAssignmentTypeId: (state, action) => {
      state.assignment_type_id = action.payload
    },
    setClassStudentIds: (state, action) => {
      state.class_student_ids = action.payload
    },
    setCourseTeacherId: (state, action) => {
      state.course_teacher_id = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getSchoolTerms.pending, (state) => {         
    }).addCase(getSchoolTerms.fulfilled,(state,action)=>{    
      state.schoolTerms = action.payload.data         
    }).addCase(getSchoolTerms.rejected, (state) => {             
    })
    .addCase(getPreLoadGradingData.pending, (state) => {              
    }).addCase(getPreLoadGradingData.fulfilled,(state,action)=>{    
      state.preLoadGradingData = action.payload.data      
    }).addCase(getPreLoadGradingData.rejected, (state) => {          
    })
    .addCase(getClassStudentsForGrades.pending, (state, action) => {      
      state.loadStudentGrades = true
      state.studentGradeHeader = []
      state.studentGradeBody = []
      state.courseGradeAssignments = []  
      state.studentGradeSummaryHeader = []
      state.studentGradeSummaryBody = []    
    }).addCase(getClassStudentsForGrades.fulfilled,(state,action)=>{
        state.loadStudentGrades = false
        state.studentGradeHeader = action.payload.data?.header ?? []
        state.studentGradeBody = action.payload.data?.body ?? []
        state.courseGradeAssignments = action.payload.data?.allAssignments ?? []

        state.studentGradeSummaryHeader = action.payload.data?.summaryHeader ?? []
        state.studentGradeSummaryBody = action.payload.data?.summaryBody ?? []
    }).addCase(getClassStudentsForGrades.rejected, (state) => {
        state.loadStudentGrades = false           
    })
    .addCase(getQuickStudentGrades.pending, (state) => {       
    }).addCase(getQuickStudentGrades.fulfilled,(state,action)=>{    
      state.studentGradeHeader = action.payload.data.header
      state.studentGradeBody = action.payload.data.body
      state.courseGradeAssignments = action.payload.data.allAssignments
    }).addCase(getQuickStudentGrades.rejected, (state) => {    
    })
    .addCase(getQuickStudentRubricGrades.pending, (state) => {  
      state.studentGradeHeader = []  
    }).addCase(getQuickStudentRubricGrades.fulfilled,(state,action)=>{    
      state.studentGradeHeader = action.payload.data.header
      state.studentGradeBody = action.payload.data.body
      state.courseGradeAssignments = action.payload.data.allAssignments
    }).addCase(getQuickStudentRubricGrades.rejected, (state) => {    
    })
    
    .addCase(getSingleStudentGradeDetail.pending, (state) => {
      state.loadSingleStudentGrading = true
      state.studentCourseHeader = []
      state.studentCourseBody = []
      state.student = {}
    }).addCase(getSingleStudentGradeDetail.fulfilled,(state,action)=>{
      state.loadSingleStudentGrading = false
      state.studentCourseHeader = action.payload.data?.studentCourseHeader
      state.studentCourseBody = action.payload.data?.studentCourseBody
      state.student = action.payload.data?.student
    }).addCase(getSingleStudentGradeDetail.rejected, (state) => {
      state.loadSingleStudentGrading = false
    })
    .addCase(getSingleStudentGraphData.pending, (state) => { 
        state.pieChartSeriesData = []
        state.pieChartLabelsData = []
        state.pieChartOverallData = 0
        state.barChartSeriesData = []
        state.barChartLabelsData = []
        state.barChartTableData = []
        // state.barChartMedianData = []

        state.barMarksMedianData = []
        state.barPercentMedianData = []
    }).addCase(getSingleStudentGraphData.fulfilled,(state,action)=>{         
      state.pieChartSeriesData = action.payload?.data?.pieSeries
      state.pieChartLabelsData = action.payload?.data?.pieLabels
      state.pieChartOverallData = action.payload?.data?.pieOverAll
      state.barChartSeriesData = action.payload?.data?.barSeries
      state.barChartLabelsData = action.payload?.data?.barLabels
      state.barChartTableData = action.payload?.data?.barTable
      // state.barChartMedianData = action.payload?.data?.barMedians

      state.barMarksMedianData = action.payload?.data?.barMarksMedians
      state.barPercentMedianData = action.payload?.data?.barPercentMedians
      
    }).addCase(getSingleStudentGraphData.rejected, (state) => {          
    })
    .addCase(getSingleSubjectDetail.pending, (state) => {  
      state.singleSubjectDetail = []  
    }).addCase(getSingleSubjectDetail.fulfilled,(state,action)=>{    
      state.singleSubjectDetail = action.payload.data
    }).addCase(getSingleSubjectDetail.rejected, (state) => {    
    })
    
  },
});

export const { 
  setSingleStudentGradingModal,
  setViewAs,
  setGradeTableMode,
  setFilterData,
  setTermDates,
  setMainGradingFilters,
  setAllClassCourseStudents,
  setClassCourseId,
  setCourseId,
  setOrganizationGradeLevelId,
  setAddQuickAssignmentModal,
  setAssignmentTypeId,
  setClassStudentIds,
  setCourseTeacherId,
  setSingleStudentDetailViewType,
  setSingleSubjectDetailDrawer

} = manageStudentGradingSlice.actions;

export default manageStudentGradingSlice.reducer;
