import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { TEACHER_REPLACEMENT, TEACHER_REPLACEMENT_API_URL, GET_ALL_TEACHER_REPLACEMENT_API_URL } from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  TeacherReplacementErrors: {},
  permission: permission?.teacher_replacement ?? [],
  TeacherReplacementShowMessage: null,
  TeacherReplacementResult: [],
  TeacherReplacementButtonSpinner: false,
  TeacherReplacementTableLoading: false,
  TeacherReplacementAddDrawer: false,
  TeacherReplacementEditData: [],
  TeacherReplacementButtonAndModelLabel: setLocale('teacherreplacement.add'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewTeacherReplacementData: [],
  selectedRowKeys: [],
  ViewTeacherReplacementLoader: true,
  DrawerStatus: 0,
};

export const createTeacherReplacement = createAsyncThunk(
  "createTeacherReplacement",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, TEACHER_REPLACEMENT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getTeacherReplacement = createAsyncThunk(
  "getTeacherReplacement",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_TEACHER_REPLACEMENT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewTeacherReplacement = createAsyncThunk(
  "viewTeacherReplacement",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, TEACHER_REPLACEMENT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteTeacherReplacement = createAsyncThunk(
  "deleteTeacherReplacement",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, TEACHER_REPLACEMENT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageTeacherReplacementSlice = createSlice({
  name: TEACHER_REPLACEMENT,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.TeacherReplacementErrors = {};
    },
    TeacherReplacementAddDrawerStatus: (state, action) => {
      if (action.payload.status === false) {
        state.TeacherReplacementButtonSpinner = false;
      }
      state.TeacherReplacementAddDrawer = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.TeacherReplacementEditData = [];
      state.TeacherReplacementButtonAndModelLabel = setLocale('teacherreplacement.add');
    },
    TeacherReplacementEditWithDrawerStatus: (state, action) => {
      state.TeacherReplacementAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.TeacherReplacementEditData = action.payload.data;
      state.TeacherReplacementButtonAndModelLabel = setLocale('teacherreplacement.edit');
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
    setSelectedRowKeys: (state, action) => {
      state.selectedRowKeys = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTeacherReplacement.pending, (state, action) => {
        state.DrawerStatus = 0
        state.TeacherReplacementButtonSpinner = true;
      }).addCase(createTeacherReplacement.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.TeacherReplacementButtonSpinner = false;
        state.TeacherReplacementErrors = {}
      }).addCase(createTeacherReplacement.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.TeacherReplacementShowMessage = true;
        state.TeacherReplacementButtonSpinner = false;
        state.TeacherReplacementErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getTeacherReplacement.pending, (state, action) => {
        state.TeacherReplacementTableLoading = true;
      }).addCase(getTeacherReplacement.fulfilled, (state, action) => {
        state.TeacherReplacementTableLoading = false;
        state.TeacherReplacementResult = action.payload;
      }).addCase(getTeacherReplacement.rejected, (state, action) => {
        state.TeacherReplacementShowMessage = true; // Set the showMessage flag to display the errors
        state.TeacherReplacementTableLoading = false;
        state.TeacherReplacementErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewTeacherReplacement.pending, (state, action) => {
        state.ViewTeacherReplacementLoader = true;
        state.ViewTeacherReplacementData = [];
      }).addCase(viewTeacherReplacement.fulfilled, (state, action) => {
        state.ViewTeacherReplacementLoader = false;
        state.ViewTeacherReplacementData = action.payload;
      }).addCase(viewTeacherReplacement.rejected, (state, action) => {
        state.ViewTeacherReplacementLoader = false;
        state.ViewTeacherReplacementData = [];
      })
      .addCase(deleteTeacherReplacement.pending, (state, action) => {
        state.TeacherReplacementTableLoading = true;
      }).addCase(deleteTeacherReplacement.fulfilled, (state, action) => {
        state.TeacherReplacementTableLoading = false;
      }).addCase(deleteTeacherReplacement.rejected, (state, action) => {
        state.TeacherReplacementTableLoading = false;
      });
  },
});

export const { onCloseError, setColumnSearch, setSelectedRowKeys, TeacherReplacementAddDrawerStatus, TeacherReplacementEditWithDrawerStatus, updateSortFilters } = manageTeacherReplacementSlice.actions;

export default manageTeacherReplacementSlice.reducer;
