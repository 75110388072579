import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  STUDENT, STUDENT_API_URL,
  GET_STUDENT_PERSONAL_INFO,
  ADD_STUDENT_PERSONAL_INFO,
  GET_STUDENT_MEDICAL_INFO,
  ADD_STUDENT_MEDICAL_INFO,
  GET_STUDENT_GUARDIAN,
  ADD_STUDENT_GUARDIAN,
  DELETE_STUDENT_GUARDIAN,
  GET_STUDENT_SIBLINGS,
  GET_GUARDIAN_CHILDRENS,
  ADD_STUDENT_SIBILING,
  DELETE_STUDENT_SIBILING,
  GET_STUDENT_ENROLLMENTS,
  ADD_STUDENT_ENROLLMENTS,
  DELETE_STUDENT_ENROLLMENT,
  GET_STUDENT_DOCUMENTS,
  GET_ALL_DOCUMENT_TYPES,
  ADD_STUDENT_DOCUMENT,
  DELETE_STUDENT_DOCUMENT,
  GET_STUDENT_ACTIVE_ENROLLMENTS,
  GET_GRADE_CHARGES,
  ADD_STUDENT_CHARGES,
  SEARCH_STUDENTS,
  GET_ALL_STUDENTS_DROPDOWN_URL,
  DELETE_STUDENT_PICTURE,
  PRE_LOAD_PROMOTE_STUDENT,
  PRE_LOAD_GRADES,
  FETCH_GRADE_STUDENTS,
  SAVE_PROMOTED_STUDENTS,
  EXPORT_STUDENT_ENROLLMENTS,
  MAKE_PRIMARY_CONTACT
} from 'constants/student/index';
import { env } from "configs/EnvironmentConfig"
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  StudentErrors: {},
  permission: permission?.student ?? [],
  StudentShowMessage: null,
  StudentResult: [],
  StudentButtonSpinner: false,
  StudentTableLoading: true,
  StudentAddDrawer: false,
  StudentEditData: [],
  StudentButtonAndModelLabel: setLocale('student.add'),
  tableStudentPagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewStudentData: [],
  ViewStudentLoader: true,
  exportButtonLoader: false,
  loadStudentData: false,
  studentProfilePicture: null,
  personalInfo: null,
  medicalInfo: null,
  studentGuardians: null,
  studentSiblings: null,
  guardianChildrens: null,
  studentEnrollments: null,
  studentDocuments: null,
  documentTypes: null,
  activeStudentEnrollment: null,
  chargeRates: null,
  searchedStudents: null,
  studentsDropdown: [],
  preLoadPromoteLoader: true,
  preLoadPromoteData: {},
  preLoadGradesLoader: false,
  preLoadGradesData: {},
  fetchGradeStudentsLoader: false,
  gradeStudents: [],

  targetStudentKeys: [],
  promoteGradeDataLoader: false,
  promoteGradeData: {},
  savePromotedStudentsLoader: false,

};

export const savePromotedStudents = createAsyncThunk("savePromotedStudents", async (data) => {
  try {
    return await CommonService.postData(data, SAVE_PROMOTED_STUDENTS);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})
export const getPromoteGradeData = createAsyncThunk("getPromoteGradeData", async (data) => {
  try {
    return await CommonService.postData(data, PRE_LOAD_GRADES);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})
export const fetchGradeStudents = createAsyncThunk("fetchGradeStudents", async (data) => {
  try {
    return await CommonService.postData(data, FETCH_GRADE_STUDENTS);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})
export const preLoadGrades = createAsyncThunk("preLoadGrades", async (data) => {
  try {
    return await CommonService.postData(data, PRE_LOAD_GRADES);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})
export const preLoadPromoteStudent = createAsyncThunk("preLoadPromoteStudent", async (data) => {
  try {
    return await CommonService.postData(data, PRE_LOAD_PROMOTE_STUDENT);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
}
)
export const createStudent = createAsyncThunk("createStudent",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, STUDENT_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getStudents = createAsyncThunk("getStudents", async (data) => {
  try {
    return await CommonService.getData(data, STUDENT_API_URL);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
}
);
export const getAllStudentsDropdown = createAsyncThunk('getAllStudentsDropdown', async (data) => {
  try {
    const response = await CommonService.getAllPost(data, GET_ALL_STUDENTS_DROPDOWN_URL);
    return response;
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
})
export const viewStudent = createAsyncThunk("viewStudent", async (id) => {
  try {
    return await CommonService.getSingleData(id, STUDENT_API_URL);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
}
);

export const deleteStudent = createAsyncThunk("deleteStudent", async (data) => {
  try {
    const response = await CommonService.deleteOne(data, STUDENT_API_URL);
    return response;
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
}
);

export const getPersonalInfo = createAsyncThunk('getPersonalInfo', async (data) => {
  try {
    return await CommonService.getData(data, GET_STUDENT_PERSONAL_INFO)
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
})
export const addUpdateStudentPersonalInfo = createAsyncThunk("addUpdateStudentPersonalInfo", async (data) => {
  try {
    return await CommonService.postData(data, ADD_STUDENT_PERSONAL_INFO)
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors));
  }
})
export const deleteStudentPicture = createAsyncThunk("deleteStudentPicture", async (data) => {
  try {
    return await CommonService.postData(data, DELETE_STUDENT_PICTURE)
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors));
  }
})
/** getStudent Medical Information */
export const getMedicalInfo = createAsyncThunk('getMedicalInfo', async (data) => {
  try {
    return await CommonService.getData(data, GET_STUDENT_MEDICAL_INFO);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})
/**add Student Medical Information */
export const addStudentMedical = createAsyncThunk('addStudentMedical', async (data) => {
  try {
    return await CommonService.postData(data, ADD_STUDENT_MEDICAL_INFO)
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})
/**get student Guardians  */
export const getStudentGuardians = createAsyncThunk('getStudentGuardians', async (data) => {
  try {
    return await CommonService.getData(data, GET_STUDENT_GUARDIAN);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
})
/**add or Sync Student Guardian */
export const addStudentGuardian = createAsyncThunk('addStudentGuardian', async (data) => {
  try {
    return await CommonService.postData(data, ADD_STUDENT_GUARDIAN)
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})
export const makePrimaryContact = createAsyncThunk('makePrimaryContact', async (data) => {
  try {
    return await CommonService.postData(data, MAKE_PRIMARY_CONTACT)
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})
/**delete guardian of Student */
export const deleteGuardian = createAsyncThunk('deleteGuardian', async (data) => {
  try {
    return await CommonService.postData(data, DELETE_STUDENT_GUARDIAN);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})
/**get student Siblings  */
export const getStudentSiblings = createAsyncThunk('getStudentSiblings', async (data) => {
  try {
    return await CommonService.getData(data, GET_STUDENT_SIBLINGS)
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})
/**get guardian Childrens  */
export const getStudentGuardianChildrens = createAsyncThunk('getStudentGuardianChildrens', async (data) => {
  try {
    return await CommonService.getData(data, GET_GUARDIAN_CHILDRENS);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})
/** add or assign new Sibling to Student */
export const addSibiling = createAsyncThunk('addSibiling', async (data) => {
  try {
    return await CommonService.postData(data, ADD_STUDENT_SIBILING);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})
/** remove student sibling */
export const deleteStudentSibling = createAsyncThunk('deleteStudentSibling', async (data) => {
  try {
    return CommonService.postData(data, DELETE_STUDENT_SIBILING)
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})

/**get student enrollments  */
export const getStudentEnrollments = createAsyncThunk('getStudentEnrollments', async (data) => {
  try {
    return await CommonService.getData(data, GET_STUDENT_ENROLLMENTS);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})
/**add Student Enrollment */
export const addStudentEnrollment = createAsyncThunk('addStudentEnrollment', async (data) => {
  try {
    return await CommonService.postData(data, ADD_STUDENT_ENROLLMENTS)
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})
/**get student enrollments  */
export const deleteStudentEnrollment = createAsyncThunk('deleteStudentEnrollment', async (data) => {
  try {
    return await CommonService.postData(data, DELETE_STUDENT_ENROLLMENT);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})
/** get documents types  */
export const getStudentDocuments = createAsyncThunk('getStudentDocuments', async (data) => {
  try {
    return await CommonService.getData(data, GET_STUDENT_DOCUMENTS)
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})
export const getAllDocumentTypes = createAsyncThunk('getAllDocumentTypes', async (data) => {
  try {
    return await CommonService.getData(data, GET_ALL_DOCUMENT_TYPES);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})
/** add student documents */
export const addStudentDocument = createAsyncThunk('addStudentDocument', async (data) => {
  try {
    return await CommonService.postData(data, ADD_STUDENT_DOCUMENT);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})
/** delete student documents */
export const deleteStudentDocument = createAsyncThunk('deleteStudentDocument', async (data) => {
  try {
    return CommonService.postData(data, DELETE_STUDENT_DOCUMENT)
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})
/**get student active enrollments  */
export const getStudentActiveEnrollment = createAsyncThunk('getStudentActiveEnrollment', async (data) => {
  try {
    return await CommonService.getData(data, GET_STUDENT_ACTIVE_ENROLLMENTS);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})
/**get grade charges  */
export const getGradeCharges = createAsyncThunk('getGradeCharges', async (data) => {
  try {
    return CommonService.getData(data, GET_GRADE_CHARGES);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})
/** save student charges */
export const addStudentCharges = createAsyncThunk('addStudentCharges', async (data) => {
  try {
    return await CommonService.postData(data, ADD_STUDENT_CHARGES);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})
/**search students  */
export const searchStudents = createAsyncThunk('searchStudents', async (data) => {
  try {
    return CommonService.getData(data, SEARCH_STUDENTS);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})

export const exportStudentsEnrollment = createAsyncThunk('exportStudentsEnrollment', async (data) => {
  try {
    return await CommonService.postData(data, EXPORT_STUDENT_ENROLLMENTS);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors))
  }
})

export const manageStudentSlice = createSlice({
  name: STUDENT,
  initialState,
  reducers: {
    setGradeStudents: (state, action) => {
      state.gradeStudents = action.payload
    },
    setTargetStudentKeys: (state, action) => {
      state.targetStudentKeys = action.payload
    },
    clearStudentFilter: (state, action) => {
      state.filter = {}
      state.sorting = {}
    },
    StudentAddDrawerStatus: (state, action) => {
      state.StudentErrors = {};
      state.StudentAddDrawer = action.payload;
      state.StudentEditData = [];
      state.StudentButtonAndModelLabel = setLocale('student.add');
    },
    StudentEditWithDrawerStatus: (state, action) => {
      state.StudentErrors = {};
      state.StudentAddDrawer = true;
      state.StudentEditData = action.payload;
      state.StudentButtonAndModelLabel = setLocale('student.edit');
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
    setStudentProfilePicture: (state, action) => {
      state.studentProfilePicture = action.payload
    },
    clearState: (state) => {
      state.StudentErrors = {};
      state.studentsDropdown = [];
    },
    setClearStudentState: (state, action) => {
      state.StudentButtonAndModelLabel = setLocale('student.add')
      state.studentProfilePicture = null
      state.personalInfo = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(savePromotedStudents.pending, (state, action) => {
        state.savePromotedStudentsLoader = true
      }).addCase(savePromotedStudents.fulfilled, (state, action) => {
        state.savePromotedStudentsLoader = false
      }).addCase(savePromotedStudents.rejected, (state, action) => {
        state.savePromotedStudentsLoader = false
      })
      .addCase(getPromoteGradeData.pending, (state, action) => {
        state.promoteGradeDataLoader = true
      }).addCase(getPromoteGradeData.fulfilled, (state, action) => {
        state.promoteGradeDataLoader = false
        state.promoteGradeData = action.payload.data
      }).addCase(getPromoteGradeData.rejected, (state, action) => {
        state.promoteGradeDataLoader = false
      })
      .addCase(fetchGradeStudents.pending, (state, action) => {
        state.fetchGradeStudentsLoader = true
      }).addCase(fetchGradeStudents.fulfilled, (state, action) => {
        state.fetchGradeStudentsLoader = false
        state.gradeStudents = action.payload.data
      }).addCase(fetchGradeStudents.rejected, (state, action) => {
        state.fetchGradeStudentsLoader = false
      })
      .addCase(preLoadGrades.pending, (state, action) => {
        state.preLoadGradesLoader = true
        state.preLoadGradesData = {}
      }).addCase(preLoadGrades.fulfilled, (state, action) => {
        state.preLoadGradesLoader = false
        state.preLoadGradesData = action.payload.data
      }).addCase(preLoadGrades.rejected, (state, action) => {
        state.preLoadGradesLoader = false
      })
      .addCase(preLoadPromoteStudent.pending, (state, action) => {
        state.preLoadPromoteLoader = true
        state.preLoadPromoteData = {}
      }).addCase(preLoadPromoteStudent.fulfilled, (state, action) => {
        state.preLoadPromoteLoader = false
        state.preLoadPromoteData = action.payload.data
      }).addCase(preLoadPromoteStudent.rejected, (state, action) => {
        state.preLoadPromoteLoader = false
      })
      .addCase(searchStudents.pending, (state, action) => {
        state.StudentButtonSpinner = true
        state.searchedStudents = null
      }).addCase(searchStudents.fulfilled, (state, action) => {
        state.StudentButtonSpinner = false
        state.loadStudentData = false
        state.StudentErrors = {}
        state.searchedStudents = action.payload.data
      }).addCase(searchStudents.rejected, (state, action) => {
        state.StudentShowMessage = true
        state.loadStudentData = false
        state.StudentButtonSpinner = false
        state.StudentErrors = JSON.parse(action.error.message)
      })
      .addCase(getPersonalInfo.pending, (state, action) => {
        state.loadStudentData = true
      }).addCase(getPersonalInfo.fulfilled, (state, action) => {
        state.StudentButtonSpinner = false
        state.loadStudentData = false
        state.StudentErrors = {}
        state.personalInfo = action.payload.data
        state.studentProfilePicture = action.payload?.data?.profile_picture ? env.FILE_ENDPOINT_URL + action.payload?.data?.profile_picture : env.img
      }).addCase(getPersonalInfo.rejected, (state, action) => {
        state.StudentShowMessage = true
        state.loadStudentData = false
        state.StudentButtonSpinner = false
        state.StudentErrors = JSON.parse(action.error.message)
      })
      .addCase(addUpdateStudentPersonalInfo.pending, (state, action) => {
        state.StudentButtonSpinner = true;
      }).addCase(addUpdateStudentPersonalInfo.fulfilled, (state, action) => {
        state.StudentButtonSpinner = false;
        state.StudentErrors = {}
        state.personalInfo = action.payload.data
        state.studentProfilePicture = action.payload?.data?.profile_picture ? env.FILE_ENDPOINT_URL + action.payload?.data?.profile_picture : env.img
      }).addCase(addUpdateStudentPersonalInfo.rejected, (state, action) => {
        state.StudentShowMessage = true;
        state.StudentButtonSpinner = false;
        state.StudentErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(deleteStudentPicture.pending, (state, action) => {
        state.StudentButtonSpinner = true;
      }).addCase(deleteStudentPicture.fulfilled, (state, action) => {
        state.StudentButtonSpinner = false
        state.StudentErrors = {}
        state.personalInfo = action.payload.data
        state.studentProfilePicture = action.payload?.data?.profile_picture ? env.FILE_ENDPOINT_URL + action.payload?.data?.profile_picture : env.img
      }).addCase(deleteStudentPicture.rejected, (state, action) => {
        state.StudentShowMessage = true;
        state.StudentButtonSpinner = false;
        state.StudentErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })

      .addCase(getMedicalInfo.pending, (state, action) => {
        state.loadStudentData = true
      }).addCase(getMedicalInfo.fulfilled, (state, action) => {
        state.StudentButtonSpinner = false
        state.loadStudentData = false
        state.StudentErrors = {}
        state.medicalInfo = action.payload.data
      }).addCase(getMedicalInfo.rejected, (state, action) => {
        state.StudentShowMessage = true
        state.loadStudentData = false
        state.StudentButtonSpinner = false
        state.StudentErrors = JSON.parse(action.error.message)
      })
      .addCase(addStudentMedical.pending, (state, action) => {
        state.StudentButtonSpinner = true;
      }).addCase(addStudentMedical.fulfilled, (state, action) => {
        state.StudentButtonSpinner = false
        state.loadStudentData = false
        state.StudentErrors = {}
        state.medicalInfo = action.payload.data
      }).addCase(addStudentMedical.rejected, (state, action) => {
        state.StudentShowMessage = true
        state.loadStudentData = false
        state.StudentButtonSpinner = false
        state.StudentErrors = JSON.parse(action.error.message)
      })
      .addCase(getStudentGuardians.pending, (state, action) => {
        state.loadStudentData = true
      }).addCase(getStudentGuardians.fulfilled, (state, action) => {
        state.StudentButtonSpinner = false
        state.loadStudentData = false
        state.StudentErrors = {}
        state.studentGuardians = action.payload.data
      }).addCase(getStudentGuardians.rejected, (state, action) => {
        state.StudentShowMessage = true
        state.loadStudentData = false
        state.StudentButtonSpinner = false
        state.StudentErrors = JSON.parse(action.error.message);
      })
      .addCase(addStudentGuardian.pending, (state, action) => {
        state.StudentButtonSpinner = true;
      }).addCase(addStudentGuardian.fulfilled, (state, action) => {
        state.StudentButtonSpinner = false
        state.loadStudentData = false
        state.StudentErrors = {}
        state.studentGuardians = action.payload.data
      }).addCase(addStudentGuardian.rejected, (state, action) => {
        state.StudentShowMessage = true
        state.loadStudentData = false
        state.StudentButtonSpinner = false
        state.StudentErrors = JSON.parse(action.error.message)
      })
      .addCase(makePrimaryContact.pending, (state, action) => {
        state.StudentButtonSpinner = true;
      }).addCase(makePrimaryContact.fulfilled, (state, action) => {
        state.StudentButtonSpinner = false
        state.loadStudentData = false
        state.StudentErrors = {}
        state.studentGuardians = action.payload.data
      }).addCase(makePrimaryContact.rejected, (state, action) => {
        state.StudentShowMessage = true
        state.loadStudentData = false
        state.StudentButtonSpinner = false
        state.StudentErrors = JSON.parse(action.error.message)
      })
      .addCase(deleteGuardian.pending, (state, action) => {

      }).addCase(deleteGuardian.fulfilled, (state, action) => {
        state.StudentButtonSpinner = false
        state.loadStudentData = false
        state.StudentErrors = {}
        state.studentGuardians = action.payload.data
      }).addCase(deleteGuardian.rejected, (state, action) => {
        state.StudentShowMessage = true
        state.loadStudentData = false
        state.StudentButtonSpinner = false
        state.StudentErrors = JSON.parse(action.error.message)
      })
      .addCase(getStudentSiblings.pending, (state, action) => {
        state.loadStudentData = true
      }).addCase(getStudentSiblings.fulfilled, (state, action) => {
        state.studentSiblings = action.payload.data
        state.StudentShowMessage = true
        state.loadStudentData = false
        state.StudentButtonSpinner = false
      }).addCase(getStudentSiblings.rejected, (state, action) => {
        state.StudentShowMessage = true
        state.loadStudentData = false
        state.StudentButtonSpinner = false
        state.StudentErrors = JSON.parse(action.error.message)
      })
      .addCase(getStudentGuardianChildrens.pending, (state, action) => {

      }).addCase(getStudentGuardianChildrens.fulfilled, (state, action) => {
        state.guardianChildrens = action.payload.data
        state.StudentShowMessage = false
        state.loadStudentData = false
        state.StudentButtonSpinner = false
      })
      .addCase(getStudentGuardianChildrens.rejected, (state, action) => {
        state.StudentShowMessage = true
        state.loadStudentData = false
        state.StudentButtonSpinner = false
        state.StudentErrors = JSON.parse(action.error.message)
      })
      .addCase(addSibiling.pending, (state, action) => {
        state.StudentButtonSpinner = true;
      }).addCase(addSibiling.fulfilled, (state, action) => {
        state.StudentButtonSpinner = false
        state.loadStudentData = false
        state.StudentErrors = {}
        state.studentSiblings = action.payload.data
      }).addCase(addSibiling.rejected, (state, action) => {
        state.StudentShowMessage = true
        state.loadStudentData = false
        state.StudentButtonSpinner = false
        state.StudentErrors = JSON.parse(action.error.message)
      })
      .addCase(deleteStudentSibling.pending, (state, action) => {
        state.StudentButtonSpinner = true
      }).addCase(deleteStudentSibling.fulfilled, (state, action) => {
        state.StudentButtonSpinner = false
        state.loadStudentData = false
        state.StudentErrors = {}
        state.studentSiblings = action.payload.data
      }).addCase(deleteStudentSibling.rejected, (state, action) => {
        state.StudentShowMessage = true
        state.loadStudentData = false
        state.StudentButtonSpinner = false
        state.StudentErrors = JSON.parse(action.error.message)
      })
      .addCase(getStudentEnrollments.pending, (state, action) => {

      }).addCase(getStudentEnrollments.fulfilled, (state, action) => {
        state.studentEnrollments = action.payload.data
        state.StudentShowMessage = false
        state.loadStudentData = false
        state.StudentButtonSpinner = false
      }).addCase(getStudentEnrollments.rejected, (state, action) => {
        state.StudentShowMessage = true
        state.loadStudentData = false
        state.StudentButtonSpinner = false
        state.StudentErrors = JSON.parse(action.error.message)
      })
      .addCase(addStudentEnrollment.pending, (state, action) => {
        state.StudentButtonSpinner = true;
      }).addCase(addStudentEnrollment.fulfilled, (state, action) => {
        state.StudentButtonSpinner = false
        state.loadStudentData = false
        state.StudentErrors = {}
        state.studentEnrollments = action.payload.data
      }).addCase(addStudentEnrollment.rejected, (state, action) => {
        state.StudentShowMessage = true
        state.loadStudentData = false
        state.StudentButtonSpinner = false
        state.StudentErrors = JSON.parse(action.error.message)
      })
      .addCase(deleteStudentEnrollment.pending, (state, action) => {
        state.StudentButtonSpinner = true
      }).addCase(deleteStudentEnrollment.fulfilled, (state, action) => {
        state.StudentButtonSpinner = false
        state.loadStudentData = false
        state.StudentErrors = {}
        state.studentEnrollments = action.payload.data
      }).addCase(deleteStudentEnrollment.rejected, (state, action) => {
        state.StudentShowMessage = true
        state.loadStudentData = false
        state.StudentButtonSpinner = false
        state.StudentErrors = JSON.parse(action.error.message)
      })
      .addCase(getStudentDocuments.pending, (state, action) => {
        state.loadStudentData = true
      }).addCase(getStudentDocuments.fulfilled, (state, action) => {
        state.studentDocuments = action.payload.data
        state.StudentShowMessage = false
        state.loadStudentData = false
        state.StudentButtonSpinner = false
      }).addCase(getStudentDocuments.rejected, (state, action) => {
        state.StudentShowMessage = true
        state.loadStudentData = false
        state.StudentButtonSpinner = false
        state.StudentErrors = JSON.parse(action.error.message)
      })
      .addCase(getAllDocumentTypes.pending, (state, action) => {

      }).addCase(getAllDocumentTypes.fulfilled, (state, action) => {
        state.documentTypes = action.payload.data
        state.StudentShowMessage = false
        state.loadStudentData = false
        state.StudentButtonSpinner = false
      }).addCase(getAllDocumentTypes.rejected, (state, action) => {
        state.StudentShowMessage = true
        state.loadStudentData = false
        state.StudentButtonSpinner = false
        state.StudentErrors = JSON.parse(action.error.message)
      })
      .addCase(addStudentDocument.pending, (state, action) => {
        state.StudentButtonSpinner = true;
      }).addCase(addStudentDocument.fulfilled, (state, action) => {
        state.StudentButtonSpinner = false
        state.loadStudentData = false
        state.StudentErrors = {}
        state.studentDocuments = action.payload.data
      }).addCase(addStudentDocument.rejected, (state, action) => {
        state.StudentShowMessage = true
        state.loadStudentData = false
        state.StudentButtonSpinner = false
        state.StudentErrors = JSON.parse(action.error.message)
      })
      .addCase(deleteStudentDocument.pending, (state, action) => {
        state.StudentButtonSpinner = true
      }).addCase(deleteStudentDocument.fulfilled, (state, action) => {
        state.StudentButtonSpinner = false
        state.loadStudentData = false
        state.StudentErrors = {}
        state.studentDocuments = action.payload.data
      }).addCase(deleteStudentDocument.rejected, (state, action) => {
        state.StudentShowMessage = true
        state.loadStudentData = false
        state.StudentButtonSpinner = false
        state.StudentErrors = JSON.parse(action.error.message)
      })
      .addCase(getStudentActiveEnrollment.pending, (state, action) => {
        state.loadStudentData = true
      }).addCase(getStudentActiveEnrollment.fulfilled, (state, action) => {
        state.activeStudentEnrollment = action.payload.data
        state.StudentShowMessage = false
        state.loadStudentData = false
        state.StudentButtonSpinner = false
      }).addCase(getStudentActiveEnrollment.rejected, (state, action) => {
        state.StudentShowMessage = true
        state.loadStudentData = false
        state.StudentButtonSpinner = false
        state.StudentErrors = JSON.parse(action.error.message)
      })
      .addCase(getGradeCharges.pending, (state, action) => {

      }).addCase(getGradeCharges.fulfilled, (state, action) => {
        state.chargeRates = action.payload.data
        state.StudentShowMessage = false
        state.loadStudentData = false
        state.StudentButtonSpinner = false
      }).addCase(getGradeCharges.rejected, (state, action) => {
        state.StudentShowMessage = true
        state.loadStudentData = false
        state.StudentButtonSpinner = false
        state.StudentErrors = JSON.parse(action.error.message)
      })
      .addCase(addStudentCharges.pending, (state, action) => {

      }).addCase(addStudentCharges.fulfilled, (state, action) => {
        state.chargeRates = action.payload.data
        state.StudentShowMessage = false
        state.loadStudentData = false
        state.StudentButtonSpinner = false
      }).addCase(addStudentCharges.rejected, (state, action) => {
        state.StudentShowMessage = true
        state.loadStudentData = false
        state.StudentButtonSpinner = false
        state.StudentErrors = JSON.parse(action.error.message)
      })


      .addCase(exportStudentsEnrollment.pending, (state, action) => {
        state.exportButtonLoader = true;
      }).addCase(exportStudentsEnrollment.fulfilled, (state, action) => {
        const fileUrl = action.payload.file_url; // Get the file URL from the response

        // Create a hidden anchor tag to initiate download
        const link = document.createElement('a');
        link.href = fileUrl;
        link.setAttribute('download', 'students_enrollment.xlsx'); // Specify the file name

        // Append the anchor to the body, trigger the click, and remove it
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        state.exportButtonLoader = false;
      }).addCase(exportStudentsEnrollment.rejected, (state, action) => {
        state.exportButtonLoader = false;
      })




      .addCase(getStudents.pending, (state, action) => {
        state.StudentButtonSpinner = true;
        state.StudentTableLoading = true;
      })
      .addCase(getStudents.fulfilled, (state, action) => {
        state.StudentButtonSpinner = false;
        state.StudentTableLoading = false;
        state.StudentResult = action.payload.data;
        state.tableStudentPagination = {
          ...state.tableStudentPagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      })
      .addCase(getStudents.rejected, (state, action) => {
        state.StudentShowMessage = true; // Set the showMessage flag to display the errors
        state.StudentButtonSpinner = false;
        state.StudentTableLoading = false;
        state.StudentErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewStudent.pending, (state, action) => {
        state.ViewStudentLoader = true;
        state.ViewStudentData = [];
      }).addCase(viewStudent.fulfilled, (state, action) => {
        state.ViewStudentLoader = false;
        state.ViewStudentData = action.payload;
      }).addCase(viewStudent.rejected, (state, action) => {
        state.ViewStudentLoader = false;
        state.ViewStudentData = [];
      })
      .addCase(deleteStudent.pending, (state, action) => {

      }).addCase(deleteStudent.fulfilled, (state, action) => {

      }).addCase(deleteStudent.rejected, (state, action) => {

      })
      .addCase(getAllStudentsDropdown.fulfilled, (state, action) => {
        state.studentsDropdown = action.payload.data;
        const list = action.payload.data;
        if (list.length > 0) {
          state.preSelect = list.map((student) => `${student.value}`);
        }
        console.log(state.preSelect)

      })
      .addCase(getAllStudentsDropdown.rejected, (state, action) => {
        state.errors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
        state.showMessage = true; // Set the showMessage flag to display the errors
        state.loading = false
      });
  },
});

export const {
  StudentAddDrawerStatus,
  StudentEditWithDrawerStatus,
  updateSortFilters,
  setColumnSearch,
  setStudentProfilePicture,
  clearState,
  setClearStudentState,
  clearStudentFilter,
  setTargetStudentKeys,
  setGradeStudents
} = manageStudentSlice.actions;

export default manageStudentSlice.reducer;
