import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {STUDENT_LEAVES, STUDENT_LEAVES_API_URL, GET_ALL_STUDENT_LEAVES_API_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  StudentLeavesErrors       : {},
  permission: permission?.student_leaves ?? [],
  StudentLeavesShowMessage  : null,
  StudentLeavesResult       : [],
  StudentLeavesButtonSpinner : false,
  StudentLeavesTableLoading : true,
  StudentLeavesAddDrawer    : false,
  StudentLeavesEditData     : [],
  StudentLeavesButtonAndModelLabel  : setLocale('studentleaves.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewStudentLeavesData: [],
    ViewStudentLeavesLoader: true,
    DrawerStatus: 0,
};

export const createStudentLeaves = createAsyncThunk(
    "createStudentLeaves",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, STUDENT_LEAVES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getStudentLeaves = createAsyncThunk(
    "getStudentLeaves",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_STUDENT_LEAVES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewStudentLeaves = createAsyncThunk(
  "viewStudentLeaves",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, STUDENT_LEAVES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteStudentLeaves = createAsyncThunk(
  "deleteStudentLeaves",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, STUDENT_LEAVES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageStudentLeavesSlice = createSlice({
  name: STUDENT_LEAVES,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.StudentLeavesErrors = {};
    },
    StudentLeavesAddDrawerStatus: (state, action) => {
        if (action.payload.status === false) {
            state.StudentLeavesButtonSpinner = false;
      }
      state.StudentLeavesAddDrawer   = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.StudentLeavesEditData    = [];
      state.StudentLeavesButtonAndModelLabel = setLocale('studentleaves.add');
    },
    StudentLeavesEditWithDrawerStatus: (state, action) => {
      state.StudentLeavesAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.StudentLeavesEditData = action.payload.data;
      state.StudentLeavesButtonAndModelLabel = setLocale('studentleaves.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createStudentLeaves.pending, (state, action) => {
        state.DrawerStatus = 0
        state.StudentLeavesButtonSpinner = true;
      }).addCase(createStudentLeaves.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.StudentLeavesButtonSpinner = false;
        state.StudentLeavesErrors = {}
      }).addCase(createStudentLeaves.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.StudentLeavesShowMessage = true;
        state.StudentLeavesButtonSpinner = false;
        state.StudentLeavesErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getStudentLeaves.pending, (state, action) => {
        state.StudentLeavesButtonSpinner = true;
        state.StudentLeavesTableLoading = true;
      }).addCase(getStudentLeaves.fulfilled, (state, action) => {
        state.StudentLeavesButtonSpinner = false;
        state.StudentLeavesTableLoading = false;
        state.StudentLeavesResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.pagination.total,
            current: action.payload.pagination.current_page,
            pageSize: action.payload.pagination.per_page
        };
      }).addCase(getStudentLeaves.rejected, (state, action) => {
        state.StudentLeavesShowMessage = true; // Set the showMessage flag to display the errors
        state.StudentLeavesButtonSpinner = false;
        state.StudentLeavesTableLoading = false;
        state.StudentLeavesErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewStudentLeaves.pending, (state, action) => {
        state.ViewStudentLeavesLoader = true;
        state.ViewStudentLeavesData = [];
      }).addCase(viewStudentLeaves.fulfilled, (state, action) => {
        state.ViewStudentLeavesLoader = false;
        state.ViewStudentLeavesData = action.payload;
      }).addCase(viewStudentLeaves.rejected, (state, action) => {
        state.ViewStudentLeavesLoader = false;
        state.ViewStudentLeavesData = [];
      })
      .addCase(deleteStudentLeaves.pending, (state, action) => {
        state.StudentLeavesTableLoading = true;
      }).addCase(deleteStudentLeaves.fulfilled, (state, action) => {
        state.StudentLeavesTableLoading = false;
      }).addCase(deleteStudentLeaves.rejected, (state, action) => {
        state.StudentLeavesTableLoading = false;
      });
  },
});

export const { onCloseError, setColumnSearch, StudentLeavesAddDrawerStatus, StudentLeavesEditWithDrawerStatus, updateSortFilters } = manageStudentLeavesSlice.actions;

export default manageStudentLeavesSlice.reducer;
