import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {ADMIN_FUNCTIONS, ADMIN_FUNCTIONS_API_URL, GET_ALL_ADMIN_FUNCTIONS_API_URL} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  AdminFunctionsErrors       : {},
  permission: permission?.admin_functions ?? [],
  AdminFunctionsShowMessage  : null,
  AdminFunctionsResult       : [],
  AdminFunctionsButtonSpinner : false,
  AdminFunctionsTableLoading : true,
  AdminFunctionsAddDrawer    : false,
  AdminFunctionsEditData     : [],
  AdminFunctionsButtonAndModelLabel  : setLocale('adminfunctions.add'),
  tablePagination: {
        current: 1,
        pageSize: 10,
        total: 0,
    },
    sorting: {},
    filter: {},
    ViewAdminFunctionsData: [],
    ViewAdminFunctionsLoader: true,
    DrawerStatus: 0,
};

export const createAdminFunctions = createAsyncThunk(
    "createAdminFunctions",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, ADMIN_FUNCTIONS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getAdminFunctions = createAsyncThunk(
    "getAdminFunctions",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_ADMIN_FUNCTIONS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewAdminFunctions = createAsyncThunk(
  "viewAdminFunctions",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, ADMIN_FUNCTIONS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteAdminFunctions = createAsyncThunk(
  "deleteAdminFunctions",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, ADMIN_FUNCTIONS_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageAdminFunctionsSlice = createSlice({
  name: ADMIN_FUNCTIONS,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.AdminFunctionsErrors = {};
    },
    AdminFunctionsAddDrawerStatus: (state, action) => {
        if (action.payload.status === false) {
            state.AdminFunctionsButtonSpinner = false;
      }
      state.AdminFunctionsAddDrawer   = action.payload.status;
      state.DrawerStatus = action.payload.errorStatus;
      state.AdminFunctionsEditData    = [];
      state.AdminFunctionsButtonAndModelLabel = setLocale('adminfunctions.add');
    },
    AdminFunctionsEditWithDrawerStatus: (state, action) => {
      state.AdminFunctionsAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.AdminFunctionsEditData = action.payload.data;
      state.AdminFunctionsButtonAndModelLabel = setLocale('adminfunctions.edit');
    },
    updateSortFilters: (state, action) => {
        state.filter = action.payload.filter;
        state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAdminFunctions.pending, (state, action) => {
        state.DrawerStatus = 0
        state.AdminFunctionsButtonSpinner = true;
      }).addCase(createAdminFunctions.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.AdminFunctionsButtonSpinner = false;
        state.AdminFunctionsErrors = {}
      }).addCase(createAdminFunctions.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.AdminFunctionsShowMessage = true;
        state.AdminFunctionsButtonSpinner = false;
        state.AdminFunctionsErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      }).addCase(getAdminFunctions.pending, (state, action) => {
        state.AdminFunctionsButtonSpinner = true;
        state.AdminFunctionsTableLoading = true;
      }).addCase(getAdminFunctions.fulfilled, (state, action) => {
        state.AdminFunctionsButtonSpinner = false;
        state.AdminFunctionsTableLoading = false;
        state.AdminFunctionsResult = action.payload;
        state.tablePagination = {
            ...state.tablePagination,
            total: action.payload.pagination.total,
            current: action.payload.pagination.current_page,
            pageSize: action.payload.pagination.per_page
        };
      }).addCase(getAdminFunctions.rejected, (state, action) => {
        state.AdminFunctionsShowMessage = true; // Set the showMessage flag to display the errors
        state.AdminFunctionsButtonSpinner = false;
        state.AdminFunctionsTableLoading = false;
        state.AdminFunctionsErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })
      .addCase(viewAdminFunctions.pending, (state, action) => {
        state.ViewAdminFunctionsLoader = true;
        state.ViewAdminFunctionsData = [];
      }).addCase(viewAdminFunctions.fulfilled, (state, action) => {
        state.ViewAdminFunctionsLoader = false;
        state.ViewAdminFunctionsData = action.payload;
      }).addCase(viewAdminFunctions.rejected, (state, action) => {
        state.ViewAdminFunctionsLoader = false;
        state.ViewAdminFunctionsData = [];
      })
      .addCase(deleteAdminFunctions.pending, (state, action) => {
        state.AdminFunctionsTableLoading = true;
      }).addCase(deleteAdminFunctions.fulfilled, (state, action) => {
        state.AdminFunctionsTableLoading = false;
      }).addCase(deleteAdminFunctions.rejected, (state, action) => {
        state.AdminFunctionsTableLoading = false;
      });
  },
});

export const { onCloseError, setColumnSearch, AdminFunctionsAddDrawerStatus, AdminFunctionsEditWithDrawerStatus, updateSortFilters } = manageAdminFunctionsSlice.actions;

export default manageAdminFunctionsSlice.reducer;
