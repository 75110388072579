import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  STUDENT_DASHBOARD,
  STUDENT_DASHBOARD_API_URL,
  GET_ALL_STUDENT_DASHBOARD_API_URL,
  GET_STUDENT_DASHBOARD_INFORMATION_API_URL,
  GET_STUDENT_GRADES_API_URL,
  GET_ANNOUNCEMENTS_API_URL,
  GET_STUDENT_ATTENDANCE_OVERALL_API_URL,
  GET_SINGLE_STUDENT_COURSES_API_URL,
} from "constants/studentDashboard/index";
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from "services/CommonService/CommonService";
import IntlMessage from "components/util-components/IntlMessage";
import { GET_ALL_STUDENT_INVOICES_API_URL } from "constants/AppConstants";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(
  localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE)
);

export const initialState = {
  StudentDashboardErrors: {},
  StudentCoursesGradesErrors: {},
  permission: permission?.student_dashboard ?? [],
  StudentDashboardShowMessage: null,
  StudentDashboardResult: [],
  StudentAttendanceOverallLoader: true,
  StudentAttendanceOverallResult: [],
  StudentCoursesGradesLoader: true,
  StudentCoursesGradesResult: [],
  StudentDashboardAccountBalance: "",
  GuardianPersonalInfoResult: [],
  StudentAttInformation: [],
  StudentAttCountInformation: [],
  StudentAttArray: [],
  StudentGradesInformation: [],
  StudentDashboardButtonSpinner: false,
  StudentDashboardTableLoading: true,
  StudentDashboardAddDrawer: false,
  StudentDashboardEditData: [],
  StudentDashboardButtonAndModelLabel: setLocale("studentdashboard.add"),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  ViewStudentDashboardData: [],
  ViewStudentDashboardLoader: true,
  courseTeacher: "",
  getAnnouncementsResult: [],
  studentPendingInvoicesData: [],
  studentAssignmentQuizArray: [],
  studentInvoicesData: [],
  studentLeaveDataArray: [],
  studentPaymentHistory: [],
  studentWisePaymentHistory: [],
  StudentWisePayment: [],
  StudentWisePaymentLoading: true,
  StudentMonthWiseMarks: [],
  ReportCardBatches: [],
  StudentMonthWiseMarksLoader: true,
};

export const createStudentDashboard = createAsyncThunk(
  "createStudentDashboard",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(
        data,
        STUDENT_DASHBOARD_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors));
    }
  }
);

export const getStudentDashboard = createAsyncThunk(
  "getStudentDashboard",
  async (data) => {
    try {
      return await CommonService.getData(
        data,
        GET_ALL_STUDENT_DASHBOARD_API_URL
      );
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors));
    }
  }
);
export const getSingleStudentCourses = createAsyncThunk(
  "getSingleStudentCourses",
  async (data) => {
    try {
      return await CommonService.postData(
        data,
        GET_SINGLE_STUDENT_COURSES_API_URL
      );
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors));
    }
  }
);

export const getStudentAttendanceOverall = createAsyncThunk(
  "getStudentAttendanceOverall",
  async (data) => {
    try {
      return await CommonService.getData(
        [],
        GET_STUDENT_ATTENDANCE_OVERALL_API_URL
      );
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors));
    }
  }
);
export const getStudentInvoices = createAsyncThunk(
  "getStudentInvoices",
  async (data) => {
    try {
      return await CommonService.getAllPost(
        data,
        GET_ALL_STUDENT_INVOICES_API_URL
      );
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors));
    }
  }
);

export const getStudentDashboardInformation = createAsyncThunk(
  "getStudentDashboardInformation",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        GET_STUDENT_DASHBOARD_INFORMATION_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getClassStudentsForGrades = createAsyncThunk(
  "getClassStudentsForGrades",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        GET_STUDENT_GRADES_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewStudentDashboard = createAsyncThunk(
  "viewStudentDashboard",
  async (id) => {
    try {
      const response = await CommonService.showOne(
        id,
        STUDENT_DASHBOARD_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteStudentDashboard = createAsyncThunk(
  "deleteStudentDashboard",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(
        data,
        STUDENT_DASHBOARD_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getAnnouncements = createAsyncThunk(
  "getAnnouncements",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(
        data,
        GET_ANNOUNCEMENTS_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageStudentDashboardSlice = createSlice({
  name: STUDENT_DASHBOARD,
  initialState,
  reducers: {
    StudentDashboardAddDrawerStatus: (state, action) => {
      state.StudentDashboardErrors = {};
      state.StudentDashboardAddDrawer = action.payload;
      state.StudentDashboardEditData = [];
      state.StudentDashboardButtonAndModelLabel = setLocale(
        "studentdashboard.add"
      );
    },
    StudentDashboardEditWithDrawerStatus: (state, action) => {
      state.StudentDashboardErrors = {};
      state.StudentDashboardAddDrawer = true;
      state.StudentDashboardEditData = action.payload;
      state.StudentDashboardButtonAndModelLabel = setLocale(
        "studentdashboard.edit"
      );
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createStudentDashboard.pending, (state, action) => {
        state.StudentDashboardButtonSpinner = true;
      })
      .addCase(createStudentDashboard.fulfilled, (state, action) => {
        state.StudentDashboardButtonSpinner = false;
        state.StudentDashboardErrors = {};
      })
      .addCase(createStudentDashboard.rejected, (state, action) => {
        state.StudentDashboardShowMessage = true;
        state.StudentDashboardButtonSpinner = false;
        state.StudentDashboardErrors = JSON.parse(action.error.message);
      })
      .addCase(getStudentDashboard.pending, (state, action) => {
        state.StudentDashboardTableLoading = true;
      })
      .addCase(getStudentDashboard.fulfilled, (state, action) => {
        state.StudentDashboardButtonSpinner = false;
        state.StudentDashboardTableLoading = false;
        state.StudentDashboardResult = action.payload.students;

        state.StudentDashboardAccountBalance = action.payload.account_balance;
        state.GuardianPersonalInfoResult = action.payload.guardian;
        state.guardianStudentAssignments = action.payload.studentAssignments;
        state.studentPendingInvoicesData = action.payload.studentPendingInvoicesArray;
        state.studentAssignmentQuizArray = action.payload.studentAssignmentQuizArray;
        state.studentLeaveDataArray = action.payload.studentLeaveDataArray;
        state.studentPaymentHistory = action.payload.studentPaymentHistory;
        state.studentWisePaymentHistory = action.payload.studentWisePaymentHistory;
      })
      .addCase(getStudentDashboard.rejected, (state, action) => {
        state.StudentDashboardShowMessage = true;
        state.StudentDashboardButtonSpinner = false;
        state.StudentDashboardTableLoading = false;
        state.StudentDashboardErrors = JSON.parse(action.error.message);
      })
      .addCase(getStudentAttendanceOverall.pending, (state, action) => {
        state.StudentAttendanceOverallLoader = true;
        state.StudentAttendanceOverallResult = [];
      })
      .addCase(getStudentAttendanceOverall.fulfilled, (state, action) => {
        state.StudentAttendanceOverallLoader = false;
        state.StudentAttendanceOverallResult = action.payload;
      })
      .addCase(getStudentAttendanceOverall.rejected, (state, action) => {
        state.StudentDashboardErrors = JSON.parse(action.error.message);
      })

      .addCase(getSingleStudentCourses.pending, (state, action) => {
        state.StudentCoursesGradesLoader = true;
        state.StudentCoursesGradesResult = [];
      })
      .addCase(getSingleStudentCourses.fulfilled, (state, action) => {
        state.StudentCoursesGradesLoader = false;
        state.StudentCoursesGradesResult = action.payload;
      })
      .addCase(getSingleStudentCourses.rejected, (state, action) => {
        state.StudentCoursesGradesErrors = JSON.parse(action.error.message);
      })
      

      .addCase(getStudentInvoices.pending, (state, action) => {
        state.StudentInvoiceTableLoading = true;
      })
      .addCase(getStudentInvoices.fulfilled, (state, action) => {
        state.StudentInvoiceTableLoading = false;
        state.studentInvoicesData = action.payload.data;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.total,
          current: action.payload.current_page,
          pageSize: action.payload.per_page,
        };
      })
      .addCase(getStudentInvoices.rejected, (state, action) => {
        state.StudentInvoiceTableLoading = false;
      })
      .addCase(getStudentDashboardInformation.pending, (state, action) => {
        state.StudentWisePaymentLoading = true;
        state.StudentMonthWiseMarksLoader = true;
        state.StudentWisePayment = [];
        state.StudentMonthWiseMarks = [];
      })
      .addCase(getStudentDashboardInformation.fulfilled, (state, action) => {
        state.StudentAttInformation = action.payload.record;
        state.StudentAttCountInformation = action.payload.count;        
        state.StudentAttArray = action.payload.attendanceArray;
        state.StudentWisePayment = action.payload.studentPayment;
        state.StudentMonthWiseMarks = action.payload.monthWiseMarks;
        state.ReportCardBatches = action.payload.ReportCardBatches;
        state.StudentWisePaymentLoading = false;
        state.StudentMonthWiseMarksLoader = false;
      })
      .addCase(getStudentDashboardInformation.rejected, (state, action) => {
        state.StudentWisePaymentLoading = false;
        state.StudentMonthWiseMarksLoader = false;
        state.StudentWisePayment = [];
        state.StudentMonthWiseMarks = [];
      })
      .addCase(getClassStudentsForGrades.fulfilled, (state, action) => {
        state.StudentGradesInformation = action.payload.data;
        state.courseTeacher = action.payload.courseTeacher?.teacher;
      })
      .addCase(viewStudentDashboard.pending, (state, action) => {
        state.ViewStudentDashboardLoader = true;
        state.ViewStudentDashboardData = [];
      })
      .addCase(viewStudentDashboard.fulfilled, (state, action) => {
        state.ViewStudentDashboardLoader = false;
        state.ViewStudentDashboardData = action.payload;
      })
      .addCase(viewStudentDashboard.rejected, (state, action) => {
        state.ViewStudentDashboardLoader = false;
        state.ViewStudentDashboardData = [];
      })
      .addCase(deleteStudentDashboard.pending, (state, action) => {})
      .addCase(deleteStudentDashboard.fulfilled, (state, action) => {})
      .addCase(deleteStudentDashboard.rejected, (state, action) => {})
      .addCase(getAnnouncements.pending, (state, action) => {
        state.StudentDashboardButtonSpinner = true;
      })
      .addCase(getAnnouncements.fulfilled, (state, action) => {
        state.StudentDashboardButtonSpinner = false;
        state.getAnnouncementsResult = action.payload.data;
      })
      .addCase(getAnnouncements.rejected, (state, action) => {
        state.StudentDashboardButtonSpinner = false;
      });
  },
});

export const {
  setColumnSearch,
  StudentDashboardAddDrawerStatus,
  StudentDashboardEditWithDrawerStatus,
  updateSortFilters,
} = manageStudentDashboardSlice.actions;

export default manageStudentDashboardSlice.reducer;
