export const STUDENT_DASHBOARD_API_URL = "student-dashboard";
export const GET_ALL_STUDENT_DASHBOARD_API_URL = "get-all-student-dashboard";
export const GET_ALL_STUDENT_INVOICES_API_URL = "get-all-student-invoices";
export const STUDENT_DASHBOARD = "manageStudentDashboardSlice";
export const GET_STUDENT_DASHBOARD_INFORMATION_API_URL = "get-student-dashboard-information";
export const GET_STUDENT_GRADES_API_URL = "get-class-students-for-grades";
export const GET_ANNOUNCEMENTS_API_URL = "get-announcements";
export const GET_SINGLE_STUDENT_COURSES_API_URL = "get-single-student-courses";
export const GET_STUDENT_ATTENDANCE_OVERALL_API_URL =
  "get-student-attendance-overall";
