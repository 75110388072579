import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  INVOICES,
  INVOICES_API_URL,
  GET_ALL_INVOICES_API_URL,
  GET_CHARGE_TYPE_INVOICE_URL,
  GET_DETAIL_CHARGE_TYPE_INVOICE_URL,
  GET_MONTHLY_WISE_INVOICE_URL,
  GET_DETAIL_MONTHLY_WISE_INVOICE_URL,
  GET_STUDENT_WISE_INVOICE_URL,
  GET_DETAIL_STUDENT_WISE_INVOICE_URL,
  GET_ANNUAL_INVOICE_URL,
  GET_DETAIL_ANNUAL_INVOICE_URL,
  DOWNLOAD_INVOICE_PDF,
  GET_INVOICE_REVENUE_SUMMARY_URL,
  GET_INVOICE_DASHBOARD_DATA_URL
} from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  InvoicesErrors: {},
  permission: permission?.invoices ?? [],
  InvoicesShowMessage: null,
  InvoicesResult: [],
  InvoicesButtonSpinner: false,
  ChargeTypeDetailStatus: false,
  MonthlyWiseInvoiceStatus: false,
  StudentWiseInvoiceStatus: false,
  AnnualInvoiceStatus: false,
  MainComponentStatus: true,
  InvoicesAddDrawer: false,
  InvoicesEditData: [],
  InvoicesButtonAndModelLabel: setLocale('invoices.add'),
  monthlyWiseTablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {
    grade_level: null,
    invoice_date: null,
    organization_id: null,
    grade_name: null
  },
  chargeTypeSorting: {},
  chargeTypeFilter: {},

  monthlyWiseFilter: {},
  monthlyWiseSorting: {},

  studentWiseSorting: {},
  studentWiseFilter: {},
  studentWiseTablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  annualFilter: {},
  annualSorting: {},
  annualTablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  ViewInvoicesData: [],
  ViewInvoicesLoader: true,

  ChargeTypeInvoicesLoading: true,
  chargeTypeInvoice: {
    series: [],
    options: {},
  },
  DetailChargeTypeInvoicesLoading: true,
  detailChargeTypeInvoice: [],

  MonthlyWiseInvoicesLoading: true,
  monthlyWiseInvoice: {
    series: [],
    options: {},
  },
  DetailMonthlyWiseInvoicesLoading: true,
  detailMonthlyWiseInvoice: [],

  StudentWiseInvoicesLoading: true,
  studentWiseInvoice: {
    series: [],
    options: {},
  },
  DetailStudentWiseInvoicesLoading: true,
  detailStudentWiseInvoice: [],

  AnnualInvoicesLoading: true,
  annualInvoice: [],
  detailAnnualInvoice: [],
  DetailAnnualInvoicesLoading: true,
  InvoiceDownloadSpinner: false,
  revenueSummary: [],
  revenueSummaryLoading: true,
  dashboardDataLoading: true,
  dashboardData: [],
  futureInvoicesArray: [],
  futureInvoicesStatus: false,
};

export const createInvoices = createAsyncThunk(
  "createInvoices",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, INVOICES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getInvoices = createAsyncThunk(
  "getInvoices",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_INVOICES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewInvoices = createAsyncThunk(
  "viewInvoices",
  async (id) => {
    try {
      const response = await CommonService.showOne(id, INVOICES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteInvoices = createAsyncThunk(
  "deleteInvoices",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, INVOICES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const chargeTypeInvoices = createAsyncThunk(
  "chargeTypeInvoices",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_CHARGE_TYPE_INVOICE_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const detailChargeTypeInvoices = createAsyncThunk(
  "detailChargeTypeInvoices",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_DETAIL_CHARGE_TYPE_INVOICE_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const monthlyWiseInvoices = createAsyncThunk(
  "monthlyWiseInvoices",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_MONTHLY_WISE_INVOICE_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const detailMonthlyWiseInvoices = createAsyncThunk(
  "detailMonthlyWiseInvoices",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_DETAIL_MONTHLY_WISE_INVOICE_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const studentWiseInvoices = createAsyncThunk(
  "studentWiseInvoices",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_STUDENT_WISE_INVOICE_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const detailStudentWiseInvoices = createAsyncThunk(
  "detailStudentWiseInvoices",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_DETAIL_STUDENT_WISE_INVOICE_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const annualInvoices = createAsyncThunk(
  "annualInvoices",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ANNUAL_INVOICE_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const detailAnnualInvoices = createAsyncThunk(
  "detailAnnualInvoices",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_DETAIL_ANNUAL_INVOICE_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const invoicesRevenueSummary = createAsyncThunk(
  "invoicesRevenueSummary",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_INVOICE_REVENUE_SUMMARY_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const invoiceDashboardData = createAsyncThunk(
  "invoiceDashboardData",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_INVOICE_DASHBOARD_DATA_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const downloadInvoice = createAsyncThunk(
  'downloadInvoice',
  async (filename) => {
    try {
      const response = await CommonService.getAllPost(filename, DOWNLOAD_INVOICE_PDF);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageInvoicesSlice = createSlice({
  name: INVOICES,
  initialState,
  reducers: {
    InvoicesAddDrawerStatus: (state, action) => {
      if (action.payload === false) {
        state.InvoicesButtonSpinner = false;
      }
      state.InvoicesErrors = {};
      state.InvoicesAddDrawer = action.payload;
      state.InvoicesEditData = [];
      state.InvoicesButtonAndModelLabel = setLocale('invoices.add');
    },
    InvoicesEditWithDrawerStatus: (state, action) => {
      state.InvoicesErrors = {};
      state.InvoicesAddDrawer = true;
      state.InvoicesEditData = action.payload;
      state.InvoicesButtonAndModelLabel = setLocale('invoices.edit');
    },
    updateSortFilters: (state, action) => {
      state.filter = {
        ...state.filter,
        invoice_date: action.payload?.invoice_date,
        grade_level: action.payload?.grade_level,
        grade_name: action.payload?.grade_name,
        organization_id: action.payload?.organization_id,
      }
      state.chargeTypeFilter = {
        ...state.chargeTypeFilter,
        invoice_date: action.payload?.invoice_date,
        grade_level: state.filter?.grade_name,
      }
      state.monthlyWiseFilter = {
        ...state.monthlyWiseFilter,
        invoice_date: action.payload?.invoice_date,
        grade_level: state.filter?.grade_name,
      }
      state.studentWiseFilter = {
        ...state.studentWiseFilter,
        invoice_date: action.payload?.invoice_date,
        grade_level: state.filter?.grade_name,
      }
      // state.annualFilter = {
      //   ...state.annualFilter,
      //   invoice_date: action.payload?.invoice_date,
      //   grade_level: state.filter?.grade_name,
      // }
    },

    updateChargeTypeFilters: (state, action) => {
      state.chargeTypeFilter = {
        ...state.chargeTypeFilter,
        invoice_date: action.payload?.invoice_date,
        grade_level: action.payload?.grade_level,
      }
    },
    updateMonthlyWiseFilters: (state, action) => {
      state.monthlyWiseFilter = action.payload.filter;
      state.monthlyWiseSorting = action.payload.sorting;
    },
    updateStudentWiseFilters: (state, action) => {
      state.studentWiseFilter = {
        ...state.studentWiseFilter,
        invoice_date: action.payload?.invoice_date,
        grade_level: action.payload?.grade_level,
        status: action.payload?.status,
      }
    },
    updateAnnualFilters: (state, action) => {
      state.annualFilter = {
        ...state.annualFilter,
        invoice_date: action.payload?.invoice_date,
        grade_level: action.payload?.grade_level,
        status: action.payload?.status,
      }
    },
    chargeTypeDrawerStatus: (state, action) => {
      state.ChargeTypeDetailStatus = action.payload?.drawerStatus
      state.MainComponentStatus = action.payload?.mainStatus
    },
    monthlyDrawerStatus: (state, action) => {
      state.MonthlyWiseInvoiceStatus = action.payload?.drawerStatus
      state.MainComponentStatus = action.payload?.mainStatus
    },
    studentDrawerStatus: (state, action) => {
      state.StudentWiseInvoiceStatus = action.payload?.drawerStatus
      state.MainComponentStatus = action.payload?.mainStatus
    },
    annualDrawerStatus: (state, action) => {
      state.AnnualInvoiceStatus = action.payload?.drawerStatus
      state.MainComponentStatus = action.payload?.mainStatus
    },
    mainDrawerStatus: (state, action) => {
      state.StudentWiseInvoiceStatus = false;
      state.MonthlyWiseInvoiceStatus = false;
      state.AnnualInvoiceStatus = false;
      state.MainComponentStatus = true;
    },
    setMonthlyColumnSearch: (state, action) => {
      state.monthlyWiseFilter = action.payload;
    },
    setStatusColumnSearch: (state, action) => {
      state.studentWiseFilter = action.payload;
    },
    setAnnualColumnSearch: (state, action) => {
      state.annualFilter = action.payload;
    },
    setFutureInvoicesStatus: (state, action) => {
      state.futureInvoicesStatus = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createInvoices.pending, (state, action) => {
        state.InvoicesButtonSpinner = true;
      }).addCase(createInvoices.fulfilled, (state, action) => {
        state.InvoicesButtonSpinner = false;
        state.InvoicesErrors = {}
        console.log(action.payload);
        if (action.payload?.warning && action.payload?.warning === true) {
          state.futureInvoicesArray = action.payload?.data;
          state.futureInvoicesStatus = true;
        }
      }).addCase(createInvoices.rejected, (state, action) => {
        state.InvoicesShowMessage = true;
        state.InvoicesButtonSpinner = false;
        state.InvoicesErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })


      .addCase(getInvoices.pending, (state, action) => {
        state.InvoicesButtonSpinner = true;
        state.InvoicesTableLoading = true;
      })
      .addCase(getInvoices.fulfilled, (state, action) => {
        state.InvoicesButtonSpinner = false;
        state.InvoicesTableLoading = false;
        state.InvoicesResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.total,
          current: action.payload.current_page,
          pageSize: action.payload.per_page
        };
      })
      .addCase(getInvoices.rejected, (state, action) => {
        state.InvoicesShowMessage = true; // Set the showMessage flag to display the errors
        state.InvoicesButtonSpinner = false;
        state.InvoicesTableLoading = false;
        state.InvoicesErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })


      .addCase(chargeTypeInvoices.pending, (state, action) => {
        state.ChargeTypeInvoicesLoading = true;
      })
      .addCase(chargeTypeInvoices.fulfilled, (state, action) => {
        state.ChargeTypeInvoicesLoading = false;
        state.chargeTypeInvoice = {
          ...state.chargeTypeInvoice,
          series: action.payload.series,
          options: action.payload.options,
        };
      })
      .addCase(chargeTypeInvoices.rejected, (state, action) => {
        state.ChargeTypeInvoicesLoading = false;
      })
      .addCase(detailChargeTypeInvoices.pending, (state, action) => {
        state.DetailChargeTypeInvoicesLoading = true;
      })
      .addCase(detailChargeTypeInvoices.fulfilled, (state, action) => {
        state.DetailChargeTypeInvoicesLoading = false;
        state.detailChargeTypeInvoice = action.payload;
      })
      .addCase(detailChargeTypeInvoices.rejected, (state, action) => {
        state.DetailChargeTypeInvoicesLoading = true; // Set the showMessage flag to display the errors
      })


      .addCase(monthlyWiseInvoices.pending, (state, action) => {
        state.MonthlyWiseInvoicesLoading = true;
      })
      .addCase(monthlyWiseInvoices.fulfilled, (state, action) => {
        state.MonthlyWiseInvoicesLoading = false;
        state.monthlyWiseInvoice = {
          ...state.monthlyWiseInvoice,
          series: action.payload.series,
          options: action.payload.options,
        };
      })
      .addCase(monthlyWiseInvoices.rejected, (state, action) => {
        state.MonthlyWiseInvoicesLoading = false;
      })
      .addCase(detailMonthlyWiseInvoices.pending, (state, action) => {
        state.DetailMonthlyWiseInvoicesLoading = true;
      })
      .addCase(detailMonthlyWiseInvoices.fulfilled, (state, action) => {
        state.DetailMonthlyWiseInvoicesLoading = false;
        state.detailMonthlyWiseInvoice = action.payload.data;
        state.monthlyWiseTablePagination = {
          ...state.monthlyWiseTablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      })
      .addCase(detailMonthlyWiseInvoices.rejected, (state, action) => {
        state.DetailMonthlyWiseInvoicesLoading = true; // Set the showMessage flag to display the errors
      })


      .addCase(studentWiseInvoices.pending, (state, action) => {
        state.StudentWiseInvoicesLoading = true;
      })
      .addCase(studentWiseInvoices.fulfilled, (state, action) => {
        state.StudentWiseInvoicesLoading = false;
        state.studentWiseInvoice = {
          ...state.studentWiseInvoice,
          series: action.payload.series,
          options: action.payload.options,
        };
      })
      .addCase(studentWiseInvoices.rejected, (state, action) => {
        state.StudentWiseInvoicesLoading = false;
      })
      .addCase(detailStudentWiseInvoices.pending, (state, action) => {
        state.DetailStudentWiseInvoicesLoading = true;
      })
      .addCase(detailStudentWiseInvoices.fulfilled, (state, action) => {
        state.DetailStudentWiseInvoicesLoading = false;
        state.detailStudentWiseInvoice = action.payload.data;
        state.studentWiseTablePagination = {
          ...state.studentWiseTablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      })
      .addCase(detailStudentWiseInvoices.rejected, (state, action) => {
        state.DetailStudentWiseInvoicesLoading = true; // Set the showMessage flag to display the errors
      })


      .addCase(annualInvoices.pending, (state, action) => {
        state.AnnualInvoicesLoading = true;
      })
      .addCase(annualInvoices.fulfilled, (state, action) => {
        state.AnnualInvoicesLoading = false;
        state.annualInvoice = action.payload;
      })
      .addCase(annualInvoices.rejected, (state, action) => {
        state.AnnualInvoicesLoading = false;
      })

      .addCase(detailAnnualInvoices.pending, (state, action) => {
        state.DetailAnnualInvoicesLoading = true;
      })
      .addCase(detailAnnualInvoices.fulfilled, (state, action) => {
        state.DetailAnnualInvoicesLoading = false;
        state.detailAnnualInvoice = action.payload.data;
        state.annualTablePagination = {
          ...state.annualTablePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      })
      .addCase(detailAnnualInvoices.rejected, (state, action) => {
        state.DetailAnnualInvoicesLoading = true; // Set the showMessage flag to display the errors
      })

      .addCase(invoicesRevenueSummary.pending, (state, action) => {
        state.revenueSummaryLoading = true;
      })
      .addCase(invoicesRevenueSummary.fulfilled, (state, action) => {
        state.revenueSummaryLoading = false;
        state.revenueSummary = action.payload;
      })
      .addCase(invoicesRevenueSummary.rejected, (state, action) => {
        state.revenueSummaryLoading = false;
      })


      .addCase(invoiceDashboardData.pending, (state, action) => {
        state.dashboardDataLoading = true;
      })
      .addCase(invoiceDashboardData.fulfilled, (state, action) => {
        state.dashboardDataLoading = false;
        state.dashboardData = action.payload;
      })
      .addCase(invoiceDashboardData.rejected, (state, action) => {
        state.dashboardDataLoading = false;
      })


      .addCase(viewInvoices.pending, (state, action) => {
        state.ViewInvoicesLoader = true;
        state.ViewInvoicesData = [];
      }).addCase(viewInvoices.fulfilled, (state, action) => {
        state.ViewInvoicesLoader = false;
        state.ViewInvoicesData = action.payload;
      }).addCase(viewInvoices.rejected, (state, action) => {
        state.ViewInvoicesLoader = false;
        state.ViewInvoicesData = [];
      })
      .addCase(deleteInvoices.pending, (state, action) => {

      }).addCase(deleteInvoices.fulfilled, (state, action) => {

      }).addCase(deleteInvoices.rejected, (state, action) => {

      })
      .addCase(downloadInvoice.pending, (state, action) => {
        state.InvoiceDownloadSpinner = true;
      }).addCase(downloadInvoice.fulfilled, (state, action) => {
        state.InvoiceDownloadSpinner = false;

        // Open the file in a new window
        // window.open(action.payload, '_blank');

        // OR, trigger a download
        window.location.href = action.payload;

      }).addCase(downloadInvoice.rejected, (state, action) => {
        state.InvoiceDownloadSpinner = false;
      });
  },
});

export const {
  setAnnualColumnSearch,
  setStatusColumnSearch,
  setMonthlyColumnSearch,
  updateMonthlyWiseFilters,
  updateStudentWiseFilters,
  updateChargeTypeFilters,
  updateAnnualFilters,
  annualDrawerStatus,
  studentDrawerStatus,
  monthlyDrawerStatus,
  chargeTypeDrawerStatus,
  InvoicesAddDrawerStatus,
  InvoicesEditWithDrawerStatus,
  setFutureInvoicesStatus,
  updateSortFilters,
  mainDrawerStatus
} = manageInvoicesSlice.actions;

export default manageInvoicesSlice.reducer;
